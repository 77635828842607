import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileList from './FileList';

function DatasetList() {
  const [datasets, setDatasets] = useState([]);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    async function fetchDatasets() {
      const response = await fetch('https://fastapi.pandonia.net/explorer/incoming_l0_datasets?directory=incoming');
      const data = await response.json();
      setDatasets(data);
    }

    fetchDatasets();
  }, []);

  const totalCount = datasets.reduce((sum, item) => sum + item.count, 0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Incoming Datasets - Total count: {totalCount}
      </Typography>
      {datasets.map((item, index) => {
        const id = `panel-${index}`;
        return (
          <Accordion 
            key={id} 
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${id}-content`}
              id={`${id}-header`}
            >
              <Typography>
                {`${item.dataset.location}, Pandora${item.dataset.pan_id}s${item.dataset.spectrometer} - Count: ${item.count}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === id && <FileList searchstring={`Pandora${item.dataset.pan_id}s${item.dataset.spectrometer}_${item.dataset.location}`} />}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
}

export default DatasetList;
