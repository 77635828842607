import React from 'react';
import DatasetList from './components/DatasetList'; // Adjust the import path if necessary
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <DatasetList />
    </React.Fragment>
  );
}

export default App;
