import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

function FileList({ searchstring }) {
  const [files, setFiles] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Function to fetch files
    async function fetchFiles() {
      try {
        const response = await fetch(`https://fastapi.pandonia.net/explorer/incoming_l0_files?directory=incoming&searchstring=${searchstring}&limit=100`);
        const data = await response.json();
        setFiles(data.files);
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        setIsLoaded(true);
      }
    }

    // Fetch files if not already loaded
    if (!isLoaded) {
      fetchFiles();
    }
  }, [searchstring, isLoaded]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <List dense>
      {files.map((file, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={`Date: ${file.date}, Status: ${file.status}`}
            secondary={`Extension: ${file.extension}, Level: ${file.level}`}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default FileList;